module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-K9P3KXB","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#e5e4df","display":"minimal-ui","icon":"src/images/framework/favicon.png","name":"Zample","short_name":"Zample","start_url":"/","theme_color":"#e5e4df","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"301ad60bfc9b639167e6eec6d66043b0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"defaultLanguage":"nl","i18nextOptions":{"fallbackLng":"nl","interpolation":{"escapeValue":false},"keySeparator":false},"languages":["en","nl"],"localeJsonSourceName":"Translation","redirect":false,"siteUrl":"https://www.zample.nl"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
