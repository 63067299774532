// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-book-js": () => import("./../../../src/pages/book.js" /* webpackChunkName: "component---src-pages-book-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-locations-index-js": () => import("./../../../src/pages/locations/index.js" /* webpackChunkName: "component---src-pages-locations-index-js" */),
  "component---src-pages-prices-js": () => import("./../../../src/pages/prices.js" /* webpackChunkName: "component---src-pages-prices-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-result-js": () => import("./../../../src/pages/result.js" /* webpackChunkName: "component---src-pages-result-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-terms-of-purchase-js": () => import("./../../../src/pages/terms-of-purchase.js" /* webpackChunkName: "component---src-pages-terms-of-purchase-js" */),
  "component---src-templates-location-js": () => import("./../../../src/templates/location.js" /* webpackChunkName: "component---src-templates-location-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */)
}

