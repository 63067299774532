import 'react-intl-tel-input/dist/main.css';
import './src/css/custom.scss';
import '@popperjs/core/dist/umd/popper.min.js';
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js';

const tooltipTriggerList = [].slice.call(
	document.querySelectorAll('[data-bs-toggle="tooltip"]')
);

tooltipTriggerList.map(function (tooltipTriggerEl) {
	return new bootstrap.Tooltip(tooltipTriggerEl);
});
